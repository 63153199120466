import { useState, useEffect } from 'react';
import './CountdownTimer.css';
import { getRemainingTimeUntilMsTimestamp } from './CountdownTimerUtils';
import Aos from 'aos';
import "aos/dist/aos.css";

const defaultRemainingTime = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }

  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);
  return (
    <div  data-aos="fade-down" className="countdown-timer">
      <span>{remainingTime.days}</span>
      <span>Days</span>
      <span>{remainingTime.hours}</span>
      <span>Hours</span>
      <span>{remainingTime.minutes}</span>
      <span>Minutes</span>
      <span>{remainingTime.seconds}</span>
      <span>Seconds</span>
    </div>
  );
};

export default CountdownTimer;
