import React,  {useEffect} from 'react';
import './TimeLinecss.css';
import Styled from 'styled-components';
import Aos from 'aos';
import "aos/dist/aos.css";

const Section = Styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${(props) => props.theme.body};
position: relative;
`;

const Judul = Styled.h3`
font-size: 32px;
text-transform: capitalize;
color: #202020;
display: flex;
justify-content: center;
alignt-items: center;
`;

const TimeLine = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Section id='roadmap'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#202020" fill-opacity="1" d="M0,128L360,64L720,64L1080,160L1440,64L1440,0L1080,0L720,0L360,0L0,0Z"></path>
      </svg>
      <Judul data-aos="fade-down"> Roadmap </Judul>
      <div class="wrapper">
        <div class="center-line"></div>
        <div class="row row-1">
          <section data-aos="fade-right" data-aos-duration="1000">
            <i class="icon fas fa-home"></i>
            <div class="details">
              <span class="title">Q3 2022</span>
            </div>
            <p><li>Build Community</li>
            <li>Do a Whitelist</li>
            <li>Do a Presale</li>
            <li>Do a Public Sale</li>
            <li>Open Learning channel about NFT ( Web3 )</li>
            <li>Build & Release A VR Gallery</li>
            <li>Release The Platform MoveZ</li></p>

          </section>
        </div>
        <div  class="row row-2">
          <section data-aos="fade-left" data-aos-duration="1000">
            <i class="icon fas fa-star"></i>
            <div class="details">
              <span class="title">Q4 2022</span>
            </div>
            <p>Coming Soon.</p>
          </section>
        </div>
        <div class="row row-1">
          <section data-aos="fade-right" data-aos-duration="1000" >
            <i class="icon fas fa-rocket"></i>
            <div class="details">
              <span class="title">Q1 2023</span>
            </div>
            <p>Coming Soon.</p>
          </section>
        </div>
        <div class="row row-2">
          <section data-aos="fade-left" data-aos-duration="1000" >
            <i class="icon fas fa-globe"></i>
            <div class="details">
              <span class="title">Q2 2023</span>
            </div>
            <p>Coming Soon.</p>
          </section>
        </div>
        <div class="row row-1">
          <section data-aos="fade-right" data-aos-duration="1000" >
            <i class="icon fas fa-paper-plane"></i>
            <div class="details">
              <span class="title">Q3 2023</span>
            </div>
            <p>Coming Soon.</p>
          </section>
        </div>
      </div>
    </Section>
  );
};

export default TimeLine;
