import React, {useEffect} from 'react';
import styled from 'styled-components';
import Slider from '../Slider';
import Aos from 'aos';
import "aos/dist/aos.css";

const Section = styled.section`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Container = styled.div`
  width: 75%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 48em) {
    width: 80%;
    flex-direction: column;
  
  }
`;
const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 40px;

  @media (max-width: 48em) {
    width: 95%;
    margin: 20px 15px
  }
    &>*:first-child{
      margin-top: 5px;
    }
  
`;
const Title = styled.h1`
  width: 80;
  margin-bottom: 10px;
  align-self: flex-start;
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
`;
const subTitle = styled.h3`
  width: 100%;
  align-self: flex-start;
  font-size: ${(props) => props.theme.fontlg};
`;
const Btn = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.text};
  color: #ffffff;
  outline: none;
  border: 2px solid #fff;
  margin-top: 10px;

  font-size: ${(props) => props.theme.fontmd};
  padding: 0.4rem 1.8rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #ffffff;
    color: #202020;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 5px;
  width: 80%;
  align-self: flex-start;
`;

const About = () => {
  useEffect(() => {
    Aos.init({duration: 1500});
  }, []);
  return (
    <Section id='about'>
      <Container>
        <Box  data-aos="fade-right">
          {' '}
          <Slider />{' '}
        </Box>
        <Box data-aos="fade-left">
          <Title> Welcomee To Our Project </Title>
          <subTitle>
            {' '}
            The MoveZ on Polygon blockchain! MoveZ is a collection of 1000 generated hand-drawn NFT with 5 different traits categories.
          Our main focus is Build a project where the project is based (from community to community) Those from the community will return to the community.
          </subTitle>
          <ButtonContainer>
          <Btn>
      <a href="#roadmap">Roadmap</a>
    </Btn>
          </ButtonContainer>
        </Box>
      </Container>

    </Section>
  );
};

export default About;
