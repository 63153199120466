import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import img1 from '../../assets/ImgSlider/img1.png';
import img2 from '../../assets/ImgSlider/img2.png';
import img3 from '../../assets/ImgSlider/img3.png';
import img4 from '../../assets/ImgSlider/img4.png';
import img5 from '../../assets/ImgSlider/img5.png';
import img6 from '../../assets/ImgSlider/img6.png';
import img7 from '../../assets/ImgSlider/img7.png';
import img8 from '../../assets/ImgSlider/img8.png';
import img9 from '../../assets/ImgSlider/img9.png';
import img10 from '../../assets/ImgSlider/img10.png';
import matic from '../../Icons/polygon.png';

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: #202020;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;
const move = keyframes`
  100% {transform: translateX(-1200px);}
  0% {transform: translateX(1200px);}
`;

const Row = styled.div`
  /* background-color: lightblue;*/
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;

  animation: ${move} 15s linear infinite ${(props) => props.direction};
`;
const ImgContainer = styled.div`
  width: 10rem;
  height: auto;
  margin: 0 1rem;
  background-color: ${(props) => props.theme.body};

  border-radius: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  background-color: #202020;
  border: 2px solid #202020;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  span {
    font-size: smaller;
    color: #fff;
    font-weight: 600;
    line-height: 1.5rem;
  }

  h1 {
    font-size: small;
    color: #fff;
    font-weight: 600;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 1rem;
    height: auto;
  }
`;

const NftItem = ({ img, number = 0, price = 0, passRef }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  };

  return (
    <ImgContainer    onMouseOver={e => pause(e)}   onMouseOut={e => play(e)} >
      <img src={img} alt="Kidz Z" />
      <Details >
        <div>
          <span>Kidz Z</span>
          <h1>#{number}</h1>
        </div>
        <div>
          <span>Price</span>
          <Price>
            <img src={matic} alt="matic" />
            <h1>{Number(price).toFixed(3)}</h1>
          </Price>
        </div>
      </Details>
    </ImgContainer>
  );
};

const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);
  return (
    <Section id='showcase'>
      <Row direction="none" ref={Row1Ref}>
        <NftItem img={img1} number={152} price={0.005} passRef={Row1Ref} />
        <NftItem img={img2} number={886} price={0.005} passRef={Row1Ref} />
        <NftItem img={img3} number={239} price={0.005} passRef={Row1Ref} />
        <NftItem img={img4} number={53} price={0.005} passRef={Row1Ref} />
        <NftItem img={img5} number={744} price={0.005} passRef={Row1Ref} />
      </Row>
      <Row direction="reverse" ref={Row2Ref}>
        <NftItem img={img6} number={234} price={0.005} passRef={Row2Ref} />
        <NftItem img={img7} number={423} price={0.005} passRef={Row2Ref} />
        <NftItem img={img8} number={465} price={0.005} passRef={Row2Ref} />
        <NftItem img={img9} number={867} price={0.005} passRef={Row2Ref} />
        <NftItem img={img10} number={989} price={0.005} passRef={Row2Ref} />
      </Row>
    </Section>
  );
};

export default Showcase;
