import React from 'react'
import styled from 'styled-components'
import '../../Components/Footer.css'

import img1 from '../../Icons/twitter.png';
import img2 from '../../Icons/instagram.png';
import img3 from '../../Icons/discord.png';


const Content = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;

    h3{
    font-size: 2.1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.5rem;
    margin-top: 3rem;
    }
    p{
      max-width: 500px;
    margin: 10px auto;
    line-height: 20px;
    font-size: 14px;
    color: #cacdd2;
    }
    img{
      width: 25px;
      transition: all 0.3s ease-in-out;

      &:hover{
        width: 30px;
      }
    }
`



const Footer = () => {
  return (
    <Content id='footer'><h3>MoveZ</h3>
    <p>MoveZ is a Move To Earn Platform, which will move the Young Generation (Gen Z).</p>
    <ul class="socials">
    <li><a href="https://twitter.com/movez121"><img src ={img1} alt = "twitter"/></a></li>
    <li><a href="#"><img src ={img2} alt = "instagram"/></a></li>
    <li><a href="https://discord.gg/jqx9eMbSMG"><img src ={img3} alt = "discord"/></a></li>
 
   
 </ul>
 <div class="footer-bottom">
 <p>copyright &copy;2022 <a href="#">MoveZ</a>  </p>
</div>
    </Content>
    
  )
}

export default Footer