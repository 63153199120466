//For Light and Dark Theme

export const light = {
  body: '#ffffff',
  text: '#202020',
  bodyRgba: 'rgba(255,255,255)',
  textRgba: 'rgba(0,0,0)',

  carouselColor: '#EEEDDE',

  fontxs: '0.625rem',
  fontsm: '0.75rem',
  fontmd: '1rem',
  fontlg: '1.25rem',
  fontxl: '1.5rem',
  fontxxl: '1.75rem',
  fontxxxl: '2rem',

  fontButton: '1.25rem',

  navHeight: '5rem',
};
export const dark = {
  body: '#202020',
  text: '#ffffff',
  bodyRgba: 'rgba(32,32,32)',
  textRgba: 'rgba(0,0,0)',

  carouselColor: '#EEEDDE',

  fontxs: '0.625rem',
  fontsm: '0.75rem',
  fontmd: '1rem',
  fontlg: '1.25rem',
  fontxl: '1.5rem',
  fontxxl: '1.75rem',
  fontxxxl: '2rem',

  fontButton: '1.25rem',

  navHeight: '5rem',
};
