import { ThemeProvider } from 'styled-components';
import GlobalStyle from './Style/GlobalStyles';
import { light } from './Style/Themes';

import Navigation from './Components/Navigation';
import Home from './Components/section/Home';
import About from './Components/section/About';
import Roadmap from './Components/section/Roadmap';
import Services from './Components/section/Services';
import Showcase from './Components/section/Showcase';
import Team from './Components/section/Team';
import Faq from './Components/section/Faq';
import Footer from './Components/section/Footer';

function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={light}>
        <Navigation />
        <Home />
        <About />
        <Services />
        <Roadmap />
        <Showcase />
        <Team />
        <Faq />
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
