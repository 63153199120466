import React from 'react'
import styled from 'styled-components'
import gambarLogo from '../assets/Logo.png'

const LogoImg = styled.h1`
font-family:'amaranth', sans-serif;
color: ${props => props.theme.text};
transition: all 0.4s Ease;

    img{
        width: 100px;
    }

@media (max-width: 64em){
    img{
        width: 80px;
    }
        
    }
&:hover{
    transform: scale(1.1)
}

`



const Logo = () => {
    return(
        <LogoImg>
            <img src = {gambarLogo} alt = "logo" >

            </img>
        </LogoImg>
    )
}

export default Logo

