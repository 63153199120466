import React from 'react'
import styled from 'styled-components'

const Btn = styled.button`
display: inline-block;
background-color : transparent;
color : ${props => props.theme.text};
outline: none;
border: none;

font-size: ${props => props.theme.fontsm};
padding: 0.5rem 1.5rem;
border: 2px solid ${props => props.theme.text};
border-radius: 50px;
cursor: pointer;
transition : all 0.3s ease; 

@media (max-width: 64em){
        width: 100px;
        height: 25px;
        font-size: 10px;
        padding:0.2rem 0.25rem;
    }

&:hover{
    background-color : ${props => props.theme.text};
    color : ${props => props.theme.body};
    transform: scale(1) translate(0,-3px);
}
   

`

const Button = ({text, Link}) => {
    return(
        <Btn>
            <a href={Link} target="_blank" rel="noreferrer">
                {text}
            </a>
        </Btn>
    )
}

export default Button