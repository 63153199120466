import React, {useState} from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import Button from '../Components/Button'


const Section = styled.section`
width:100vw;
background-color: ${props => props.theme.body};
`
const NavBar = styled.nav`
display:flex;
justify-content:space-between;
align-items:center;

width:85%;
height: 5rem;
margin:0 auto;

.mobile{
  display: none;
}

@media (max-width: 64em){
  .desktop{
    display: none;
  }
  .mobile{
    display: inline-block;
  }
}
@media (max-width: 48em){
  .desktop{
    display: none;
  }
  .mobile{
    display: inline-block;
  }
}

`
const Menu = styled.ul`
display:flex;
justify-content: space-between;
align-items: center;
list-style: none;

@media (max-width: 64em){
  position: fixed;
  top: ${props => props.theme.navHeight};
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: ${props =>  `(100vh - ${props.theme.navHeight})` };
  z-index: 50;
  background-color: #ffffff99;
  backdrop-filter: blur(2px);

  transform: ${props => props.click ? `translateY(0)` : 'translateY(100%)'};
  transition: all 0.5s ease;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

`

const MenuItems = styled.li`

padding: 0.5rem 0;
margin: 0 1rem;
color: ${props => props.theme.text};
cursor: pointer;
font-size: 0.8rem;
transition : 0.3s ease;

&:hover{
  transform: scale(1.08) translate(0,-3px);

}


&::after{
  
  content: ' ';
  margin : 3px 0;
  display: block;
  width : 0%;
  height : 5%;
  border-radius: 15px;
  background: ${props => props.theme.text};
  transition: width 0.3s ease;
}
&:hover::after{
width : 100%
}
@media (max-width:48em){
  &:hover::after{
width : 0;
}
}

`

const MenuHamburger = styled.span`
  width: ${props => props.click ? '2rem' : '1.5rem'};
  height: 2px;
  background: ${props => props.theme.text};

  position: absolute;
  left: 80%;
  transform: ${props => props.click ? 'translateX(-5%) rotate(90deg)' : 'translaterX(-50%) rotate(0)'};

  display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  cursor: pointer;

  transition: all 0.5s ease;

  @media (max-width: 64em){
    display: flex;
  }

  &::after, ::before{
    content: ' ';
    width: ${props => props.click ? '1rem' : '1.5rem'};
    height: 2px;
    right: ${props => props.click ? '-2px' : '0'};
    background: ${props => props.theme.text};
    position: absolute;
  }

  &::after{
    top: ${props => props.click ? '0.3rem' : '0.5rem'};
    transform: ${props => props.click ? 'rotate(-40deg)' : 'rotate(0)'};

  }
  &::before{
    bottom: ${props => props.click ? '0.3rem' : '0.5rem'};
    transform: ${props => props.click ? 'rotate(40deg)' : 'rotate(0)'};
  }
`

const Navigation = () => {
const [click, setClick] = useState(false);

const scrollTo = (id) => {
  let element = document.getElementById(id);

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'

  })

setClick(!click);

};

  return (
<Section> 
  <NavBar>
    <Logo />
    <MenuHamburger click= {click} onClick={() => setClick(!click)}>
      &nbsp;
    </MenuHamburger>
   <Menu click= {click}>
     <MenuItems onClick={() => scrollTo('home')}> Home</MenuItems>
     <MenuItems onClick={() => scrollTo('about')}> About</MenuItems>
     <MenuItems onClick={() => scrollTo('services')}>Services</MenuItems>
     <MenuItems onClick={() => scrollTo('roadmap')}> Roadmap</MenuItems>
     <MenuItems onClick={() => scrollTo('showcase')}> Gallery</MenuItems>
     <MenuItems onClick={() => scrollTo('team')}>Team</MenuItems>
     <MenuItems onClick={() => scrollTo('faq')}>Faq</MenuItems>
     <MenuItems onClick={() => scrollTo('footer')}>Footer</MenuItems>
     <MenuItems><div class="mobile">
    <Button text="Connect Wallet" link= "https://google.com"></Button>
    </div></MenuItems>
    
   </Menu>
   <div class="desktop">
    <Button text="Connect Wallet" link= "https://google.com"></Button>
    </div>
    
    </NavBar>
</Section>
  )
}

export default Navigation;
