import React, {useState, useEffect} from 'react'
import Styled from 'styled-components'
import axios from 'axios'
import Aos from 'aos';
import "aos/dist/aos.css";
import "./WhitelistForm.css"

const Container = Styled.div`
    width: 100%;
    height: 100%;
`

const Wrapper = Styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   position: relative;

   .form-group{
    width: 500px;
    height: auto;
    align-items: center;
    justify-content:center;
    display: flex;
    flex-direction: column;
   }
   .form-control{
    width: 100%;
    padding: 10px 5px;
    border: 2px solid #202020;
    border-radius: 15px 0 15px 0;
   }
   
   @media screen {
    @media (max-width: 768px) {
      .form-group{
        width: 300px;
      }
    }
   }
`

const ButtonContainer = Styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;

  .btn{
    color:white;
    font-size:18px;
    background-color: #202020;
    padding: 10px;
    border-radius: 15px 0 15px 0;
    cursor: pointer;
    transition: 0.3s;

    &:hover{
      font-size:20px;
    }
  }
  @media screen {
    @media (max-width: 768px) {
      .btn{
        padding: 8px;
        font-size: 15px;
      }
    }
   }
`


const WhitelistForm = () => {
  const [modal, setModal] = useState(false);

  const toggleModal = () =>{
    setModal(!modal)
  }

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  const [discord, setDiscord] =useState('');
  const [twitter, setTwitter] =useState('');
  const [address, setAddress] =useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const data ={
      Discord: discord,
      Twitter: twitter,
      Address: address
    }
    axios.post('https://sheetdb.io/api/v1/jspoqvlod9y0l', data).then((response)=>{
      toggleModal(modal);
      setDiscord('');
      setTwitter('');
      setAddress('');
    })
  }

  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Container>
        <Wrapper data-aos="fade-up" data-aos-duration="1000">
        <br></br>
        <h1> Join Our Whitelist!! </h1>
        <br></br>
        <form autoComplete='off' className='form-group'
        onSubmit = {handleSubmit}>
            <label>Username Discord</label>
            <input type='text' placeholder='Enter Username Discord'required className='form-control'
            onChange={(e) => setDiscord(e.target.value)} value = {discord}/>
            <br></br>   
            <label>Username Twitter</label>
            <input type='text' placeholder='Enter Username Twitter'required className='form-control'
             onChange={(e) => setTwitter(e.target.value)} value = {twitter}/>
            <br></br>   
            <label>Your 0x Adress</label>
            <input type='text' placeholder='Enter Your 0x Adress'required className='form-control'
            onChange={(e) => setAddress(e.target.value)} value = {address}/>
            <br></br>
            <ButtonContainer>
              <button type='submit' className="btn btn-primary">Join Whitelist</button>
            </ButtonContainer>
            <br></br>
        </form> 
       {modal &&(
        <div className="modal">
        <div onClick={toggleModal} className="overlay"></div>
        <div className="modal-content">
          <h2>Congrats!!</h2>
          <p>
          You have been listed on our whitelist.
          </p>
          <button className="close-modal" onClick={toggleModal}>
            OK
          </button>
        </div>
      </div>
       )}
        </Wrapper>
    </Container>
  )
}

export default WhitelistForm