import React from 'react';
import TimeLine from '../TimeLine';

const Roadmap = () => {
  return (
    <TimeLine/>
  )
};

export default Roadmap
