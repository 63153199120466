import React, {useEffect} from 'react';
import Styled from 'styled-components';
import '../../Components/Services.css';
import wallet from '../../assets/img/metamask.png';
import camera from '../../assets/img/nft.png';
import gift from '../../assets/img/coin.png';
import CountdownTimer from '../../Components/CountdownTimer';
import Aos from 'aos';
import "aos/dist/aos.css";

const Container = Styled.section`
width: 100wh;
height: 100vh;
`;

const Services = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Container id='services' >
      <section  class="services">
        <div  data-aos="fade-up" data-aos-duration="1000" class="Header">MINT NFTS (WHITELIST)</div>
        <CountdownTimer  countdownTimestampMs={1658292905000} />
        <div  class="cards">
          <div data-aos="fade-right"  data-aos-duration="500" class="card">
            <div class="light"></div>
            <img src={wallet} alt="wallet" />
            <h3>Prepare Your Wallet</h3>
            <p>Prepare Your Wallet and Mint The NFT.</p>
          </div>
          <div  data-aos="zoom-out" data-aos-offset="200" data-aos-duration= "1000" class="card">
            <div class="light"></div>
            <img src={camera} alt="camera" />
            <h3>Mint The NFT</h3>
            <p>Mint The NFT to get Access to the APPS.</p>
          </div>
          <div data-aos="fade-left" data-aos-duration= "500" class="card">
            <div class="light"></div>
            <img src={gift} alt="gift" />
            <h3>Move & Earn</h3>
            <p>Move and you will get attractive rewards.</p>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default Services;
