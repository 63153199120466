import React, {useEffect} from 'react';
import '../../Components/Team.css';
import styled from 'styled-components';
import Aos from 'aos';
import "aos/dist/aos.css";

import Twitter from '../../Icons/Twitter';

import img1 from '../../assets/ImgSlider/img1.png';
import img2 from '../../assets/ImgSlider/img2.png';

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: #202020;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 25px;
`;

const Team = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Section id='team'>
      <h1  data-aos="zoom-out" data-aos-duration="1000">Our Team</h1>
      <div class="container">
        <div data-aos="fade-right" data-aos-duration="1000" class="card">
          <div class="content">
            <div class="imgBx">
              <img src={img1} alt="" />
            </div>
            <div class="contentBx">
              <h4>Dwiki</h4>
              <h5>Founder & Programmer</h5>
            </div>
            <div class="sci">
              <a href="https://www.google.com">
                <Twitter />
              </a>
            </div>
          </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000" class="card">
          <div class="content">
            <div class="imgBx">
              <img src={img2} alt="" />
            </div>
            <div class="contentBx">
              <h4>Daniel</h4>
              <h5>Marketing & Design</h5>
            </div>
            <div class="sci">
              <a href="https://www.google.com">
                <Twitter />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Team;
