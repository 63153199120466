import {useState,useEffect} from 'react'
import Styled from 'styled-components'
import Aos from 'aos';
import "aos/dist/aos.css";


const Container = Styled.div`
cursor: pointer;
padding: 1rem 0.5rem;
display: flex;
flex-direction: column;
border-bottom: 1px solid #202020;
margin: 3rem 0;
`
const Title = Styled.div`
font-size: medium;
display: flex;
justify-content: space-between;
align-items: center;
`
const Reveal = Styled.div`
display: ${(props) => props.clicked ? 'block' : 'none'};
margin-top: 1rem;
color: ${props => `rgba(${props.theme.bodyRgba}, 0.6)`};
font-size: small;
font-weight: 300;
line-height: 1.5rem; 

`
const Name = Styled.div`
display: flex;
align-items: center;
`
const Indicators = Styled.span`
display: flex;
font-size: medium;
justify-content:center;
align-items:center;
`

const Accordion = ({title, children}) => {
  const [collapse, setCollapse] = useState(false) 
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Container data-aos="fade-up" data-aos-duration="1000" >
    <Title onClick={() => setCollapse(!collapse)}>
      <Name>
        <span>{title}</span>
      </Name>
      {
        collapse?
        <Indicators>-</Indicators> : <Indicators>+</Indicators>
      }
      </Title>
    <Reveal clicked= {collapse}>
      {children}</Reveal>
    </Container>
  )
}

export default Accordion