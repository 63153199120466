import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import Aos from 'aos';
import "aos/dist/aos.css";

const Title = styled.h2`
  font-size: 2.5rem;
  text-transform: capitalize;
  width: 100%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;

  @media (max-width: 70em){
    font-size: x-large;
  }
  @media (max-width: 48em){

  align-self: center;
  text-align: center;
  }
  @media (max-width: 40em){
  width: 90%;

  }

  .span {
    text-transform: uppercase;
    font-family: 'amaranth', sans-serif;


  .text-1 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 2rem;
  }
  .text-2 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 2rem;
  }
  .text-3 {
    color: ${(props) => props.theme.text};
    font-size: 2rem;
  }

 
}
`;

const Subtitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  margin-top: 1rem;
  width: 100%;
  align-self: flex-start;

  @media (max-width: 48em){
  align-self: center;
  text-align: center;
}
  @media (max-width: 40em){
  width: 100%;
  font-size: 1rem;

}
`;
const ButtonContainer = styled.div`
  margin-top: 5px;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 64em){
    width: 100%;
  }
  @media (max-width: 48em){
  align-self: center;
  text-align: center;
  }
`;
const Btn = styled.button`
display: inline-block;
background-color : ${props => props.theme.text};
color : #ffffff;
outline: none;
border: none;
margin-top: 10px;

font-size: ${props => props.theme.fontmd};
padding: 0.4rem 1.8rem;
border-radius: 50px;
cursor: pointer;
transition : all 0.3s ease; 

&:hover{
    background-color : ${props => props.theme.text};
    color : ${props => props.theme.body};
    transform: scale(1) translate(0,-3px);
}

`

const TypeWriterText = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <>
    <container data-aos="fade-right">
      <Title>
        {' '}
        Let's Grow Together
        <Typewriter
          class="typewrite"
          options={{
            autostart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString('<span class="text-1"> From Community</span>')
              .pauseFor(1000)
              .deleteAll()
              .typeString('<span class="text-2">To Community.</span>')
              .pauseFor(2000)
              .deleteAll()
              .typeString('<span class="text-3">With MoveZ.</spam>')
              .pauseFor(5000)
              .start();
          }}
        />
      </Title>
      <Subtitle>We Want Build Strongest Community</Subtitle>
      <ButtonContainer>
      <Btn>
            <a href ="https://discord.gg/jqx9eMbSMG"> Join Community</a>
        </Btn>
      </ButtonContainer>
      </container>
    </>
  );
};

export default TypeWriterText;
