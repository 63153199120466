import React from 'react';
import styled from 'styled-components';
import TypeWriterText from '../TypeWriterText';
import CoverImage from '../CoverImage';

const Section = styled.section`
  height: 120vh;
  width: 100vw;
  position: relative;
  background: #fff;

  @media (max-width: 64em){
    height: 100vh;
  }
  @media (max-width: 40em){
    height: 90vh;
  }
`;

const Container = styled.div`
  width: 80%;
  height: 80vh;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em){
    width: 85%;
  }

  @media (max-width: 40em){
    width: 100%;
    flex-direction: column-reverse;
    &>*:first-child{
      width: 100%;
      margin-top: 2em;
    }
  }
`;
const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <Section id='home'>
      <Container>
        <Box>
          <TypeWriterText />
        </Box>
        <Box>
          <CoverImage />
        </Box>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#202020" fill-opacity="1" d="M0,160L288,192L576,128L864,160L1152,192L1440,96L1440,320L1152,320L864,320L576,320L288,320L0,320Z"></path>
      </svg>
    </Section>
  );
};

export default Home;
