import React from "react";
import Styled from 'styled-components'
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Navigation, Autoplay, EffectCards } from "swiper";


import img1 from '../assets/ImgSlider/img1.png'
import img2 from '../assets/ImgSlider/img2.png'
import img3 from '../assets/ImgSlider/img3.png'
import img4 from '../assets/ImgSlider/img4.png'
import img5 from '../assets/ImgSlider/img5.png'

const Container = Styled.div`
width: 25vw;
height: 70vh;

@media (max-width: 48em) {
  height: 45vh;
  width: 30vh;
  margin-right: 20px;
}


.swiper{
    width: 112%;
    height: 90%;
    margin-right: 20px ;
}

.swiper-slide{
  background: rgba( 255, 255, 255, 0.3 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  border-radius: 10px 0 10px 0;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
    margin-top: 10px;

    display: flex;
    justify-content: flex-start;
    alignt-items: flex-start;


}
.swiper-button-next{
    color : white;
    width : 50px;
    height : 50px;
    margin-top : 10px;
}
.swiper-button-prev{
    color : white;
    width : 50px;
    height : 50px;
    margin-top : 10px;
}

img{
    width: 100%;
    height: 100%;
    opacity: 0.8;
    
}
`

const Slider = () => {
  return (
    <Container>
       <Swiper
       autoplay={{
        delay: 3000,
        disableOnInteraction: false,
       }}

       pagination={{
           type: 'fraction',
       }}
       navigation={true}
        effect={"cards"}
        grabCursor={true}
        modules={[ Pagination, Navigation, Autoplay, EffectCards]}
        className="mySwiper"
      >
        <SwiperSlide> <img src={img1} alt= " Bored Bird "/> </SwiperSlide>
        <SwiperSlide> <img src={img2} alt= " Bored Bird "/> </SwiperSlide>
        <SwiperSlide> <img src={img3} alt= " Bored Bird "/> </SwiperSlide>
        <SwiperSlide> <img src={img4} alt= " Bored Bird "/> </SwiperSlide>
        <SwiperSlide> <img src={img5} alt= " Bored Bird "/> </SwiperSlide>
        
      </Swiper>
    </Container>
  )
}

export default Slider