import React, {useEffect} from 'react';
import styled from 'styled-components';
import image from '../assets/ImgRightSide.png';
import Aos from 'aos';
import "aos/dist/aos.css";

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    width: 100%;
    height: auto;

    @media (max-width: 40em){
    width: 300px;
  }
  }

  @media (max-width: 64em){
    min-height: 40vh;
  }
`;

const CoverImage = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <ImageContainer >
      <img  data-aos="fade-left" src={image} />
    </ImageContainer>
  );
};

export default CoverImage;
