import React, {useEffect} from 'react';
import Styled from 'styled-components';
import Accordion from '../Accordion';
import Aos from 'aos';
import "aos/dist/aos.css";
import WhitelistForm from '../WhitelistForm';

const Section = Styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${(props) => props.theme.body};
position: relative;

display: flex;
justify-content: center;
align-items:center;
flex-direction: column;
`;

const Title = Styled.h3`
font-size: 32px;
text-transform: capitalize;
color: #202020;
display: flex;
justify-content: center;
alignt-items: center;
`;

const Container = Styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-items: center;

@media (max-width: 48em) {
  flex-direction:column;
  margin: auto;
}
`

const Box = Styled.div`
width: 45%;

@media (max-width: 48em) {
  width: 100%;
}
`

const Faq = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);
  return (
    <Section id='faq'>
      <Title>FAQ</Title>
      <Container>
        <Box>
          <Accordion  title=" What is MoveZ?"> MoveZ is a Move To Earn Platform, which will move the Young Generation (Gen Z).</Accordion>
        <Accordion  title=" What's the benefit for the holders?"> As you can see the name of our project is inspired by GEN Z where GEN Z and Millennial people really like to lie down, scroll social media which has a bad effect on their bodies, Therefore we will develop a platform called ZTEP which will provide 2 The main benefits are health and passive income.</Accordion>
        <Accordion  title=" Will the Rewards from the Platform use our own Tokens?"> NO! because of what, because if we make our own tokens and make them as rewards on the platform, what we are afraid of is the price of the tokens is very low so we can't give profit to the HOLDER (Community). </Accordion>
        </Box>
        <Box>
          <Accordion  title=" On the Kidz Z discord server there is a channel called Kidz Z Bank, what does it do?"> Its function is to monitor the total assets of the projects we build, we will update information about the assets of our projects every 2 weeks on the channel so that the HOLDER (Community) can know whether the assets are increasing or decreasing.</Accordion>
        <Accordion  title=" When will the apps be released?"> Apps are still in 60% development and for now we can't release them because there are still many bugs regarding the Withdraw system and step calculation so that there is no cheating. </Accordion>
        <Accordion  title=" Will there be a whitelist?"> There is no whitelist, we will do a pre sale but specifically for our discord community.</Accordion>
        </Box>

      </Container>
      <WhitelistForm/>
    </Section>
  )
};

export default Faq
