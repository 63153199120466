import { createGlobalStyle } from 'styled-components';
import '@fontsource/amaranth';

const GlobalStyle = createGlobalStyle`

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
    overflow-x: hidden;
    background: #202020;
}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}

a{
color: inherit;
text-decoration: none;

}
`;

export default GlobalStyle;
